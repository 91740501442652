
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VueDOMPurifyHTML from 'vue-dompurify-html';
import 'reset-css';
import "@fontsource/fira-sans";
import "@fontsource/open-sans";
import '@/assets/base.css';
import App from './App.vue';
import router from './router/index.js';

const app = createApp(App);
if (process.env.NODE_ENV === "production") {
  /* error monitoring */
  Sentry.init({
    app,
    dsn: "https://884f422e337740409318ebfc7768ce18@o1427127.ingest.sentry.io/6776411",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", /^\//],
      }),
    ],
    ignoreErrors: [
      'Non-Error exception captured'
    ],
    tracesSampleRate: 1.0,
  });
  /* self xss warning */
  const warningTitleCSS = 'color:red; font-size:50px; font-weight: bold; -webkit-text-stroke: 1px black;';
  const warningDescCSS = 'font-size: 16px;';

  console.log('%cStop!', warningTitleCSS);
  console.log("%cThis is a browser feature intended for developers. If anyone other than a trusted technician has told you to copy and paste something here to enable a Brinkee feature or \"impersonate\" someone's account, it is a scam. Doing so might give them access to your Brinkee account and/or your company's Brinkee platform.", warningDescCSS);
  console.log('%cSee https://www.brinkee.com/platform/safety/ for more information.', warningDescCSS);
}

app.use(VueDOMPurifyHTML);
app.use(createPinia());
app.use(router);

app.config.warnHandler = (msg, instance, trace) => console.warn(msg, instance, trace);
app.mount('#app');