import { createRouter, createWebHistory } from 'vue-router'
import { useAuth } from "@/stores/auth.js";
import { useUI } from "@/stores/ui.js";
import authService from '../services/auth.service.js';
import { useCookies } from '@vueuse/integrations/useCookies';
import routes from "./routes.js";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});
/**
 * Get the normalize path, without query parameters we should not track (e.g. ?tab)
 * @param {import('vue-router').RouteLocationNormalized} loc 
 * @returns 
 */
const getRedirectionPath = (loc) => {
  const path = loc.path;
  const query = new URLSearchParams(loc.query);
  query.delete("tab")
  return `${ path }?${ query.toString() }`;
}

router.beforeEach(async (to, from) => {
  const authStore = useAuth();
  const cookies = useCookies(['csrf']);
  const uiStore = useUI();
  try {
    // trying to access a private page and we are not logged in on the frontend but have a csrf cookie
    if(to.meta.private && !authStore.loggedIn && cookies.get("csrf")) {
      const { data, } = await authService.getProfile();
      authStore.login(data.data);
    }
    // trying to access /login or /upgrading and we are not logged in on the frontend but have a csrf cookie
    if((to.path === "/login" || to.path === "/upgrading" && !to.query.force) && !authStore.loggedIn && cookies.get("csrf")) {
      const { data, } = await authService.getProfile();
      authStore.login(data.data);
      return {
        path: "/hub",
      };
    }
    // trying to access a private page but we are not logged in on the frontend and have no csrf cookie
    if(to.meta.private && !authStore.loggedIn && !cookies.get("csrf")) {
      uiStore.setRedirectionPath(to.fullPath);
      return {
        path: "/login",
      };
    }
    /* logged in */
    if(to.meta.private && authStore.loggedIn) {
      if(to.meta.adminOnly && !authStore.hasRole("admin")) {
        return "/hub";
      }

      const prev = getRedirectionPath(from), curr = getRedirectionPath(to);
      /* navigates to a different page */
      if(prev !== curr) {
        uiStore.setRedirectionPath(prev);
      }
    }
    return;
  } catch (error) {
    // backend is down / critical error
    if(error.status === 502) {
      return { 
        path: "/upgrading",
        query: {
          force: true,
        }
      };
    } 
    // we are not authenticated and not on the login page
    if (error.status === 401 && to.path !== "/login") {
      return {
        path: "/login",
      };
    }
    return;
  }
  
})

export default router;
