<template>
    <output :class="className" role="status">
        <BIcon :icon="iconName" class="toast-icon" />
        <span class="toast-content">
            {{ uiStore.translate(toast.message) }}
        </span>
    </output>
</template>

<script setup>
import { ref, computed, onMounted, } from "vue";
import BIcon from "@/components/BIcon.vue";
import { useUI } from "@/stores/ui.js";
const uiStore = useUI();
const props = defineProps({
    toast: {
        type: Object,
        default: {},
    },
});
const timerRef = ref();
const className = computed(() => `toast toast--${ props.toast.type || 'default' }`);
const iconName = computed(() => {
    if(props.toast.icon) {
        return props.toast.icon;
    } else if(props.toast.type === "green") {
        return "checkbox-marked-circle-outline";
    } else if(props.toast.type === "red") {
        return "alert-circle";
    }
});
onMounted(() => {
    timerRef.value = setTimeout(() => {
        uiStore.removeToast(props.toast.id);
    }, props.toast.duration || 4000);
});

</script>

<style scoped>
.toast {
    background-color: white;
    padding: 10px 20px;
    border-radius: 5px;
    display: block;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: bold;
    will-change: transform;
    animation: fade-in .3s ease, slide-in .3s ease;
    user-select: none;
    display: flex;
    align-items: center;
}
.toast-content {
    padding-left: 20px;
    line-height: 40px;
    vertical-align: top;
}
.toast-icon {
    font-size: 30px;
}
.toast--default {
    background-color: var(--default-color);
    border-color: var(--default-border-color);
}
.toast--green {
    background-color: var(--green-color);
    border-color: var(--green-border-color);
}
.toast--yellow {
    background-color: var(--yellow-color);
    color: rgba(0,0,0,.7);
    border-color: var(--yellow-color);
}
.toast--red {
    background-color: var(--red-color);
    border-color: var(--red-color);
}
.toast--blue {
    background-color: var(--blue-color);
    border-color: var(--blue-border-color);
}

@keyframes fade-in {
  from { opacity: 0 }
}
@keyframes slide-in {
  from { transform: translateY(5px) }
}
</style>