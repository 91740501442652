<template>
    <i :class="className" aria-hidden="true" />
</template>

<script setup>
import { computed, } from "vue";
const props = defineProps({
    icon: {
        type: String,
        default: "default",
    },
    type: {
        type: String,
        default: "inherit",
    },
    spin: {
        type: Boolean,
        default: false,
    },
    inline: {
        type: Boolean,
        default: false,
    },
});
const className = computed(() => `icon--${ props.type || "inherit" } ${ props.inline ? 'icon--inline' : '' } mdi mdi-${ props.icon } ${ props.spin ? 'mdi-spin' : '' }`);
</script>

<style scoped>
.icon--inherit {
    color: inherit;
}
.icon--inline {
    padding: 6px 0 6px 8px;
    display: inline-block;
    vertical-align: middle;
}
.icon--default {
    color: var(--default-color);
}
.icon--default-light {
    color: var(--default-light-color);
}
.icon--red {
    color: var(--red-color);
}
.icon--red-light {
    color: var(--red-light-color);
}
.icon--green {
    color: var(--green-color);
}
.icon--green-light {
    color: var(--green-light-color);
}
.icon--yellow {
    color: var(--yellow-color);
}
.icon--yellow-light {
    color: var(--yellow-light-color);
}
.icon--blue {
    color: var(--blue-color);
}
.icon--blue-light {
    color: var(--blue-light-color);
}
</style>