import instance from "./base.js";
const notificationService = {
    markAsRead(uid) {
        return instance.patch(`/notification/${ uid }`);
    },
    deleteNotification(uid) {
        return instance.delete(`/notification/${ uid }`);
    },
    getNotifications() {
        return instance.get("/notification");
    }
};

export default notificationService;