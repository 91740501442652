import instance from "./base.js";

const i18nService = {
    getTranslations(language) {
        return instance.get(`/i18n/${ language }.json?v=${ __APP_VERSION__ }`, { 
            baseURL: window.location.origin,
        });
    }
};

export default i18nService;