import instance from "./base.js";


const favoriteService = {
    getFavorites() {
        return instance.get("/sidebar/favorite");
    },
    createFavorite(payload) {
        return instance.post("/sidebar/favorite", payload);
    },
    updateFavorite(uid, payload) {
        return instance.patch(`/sidebar/favorite/${ uid }`, payload);
    },
    deleteFavorite(uid) {
        return instance.delete(`/sidebar/favorite/${ uid }`);
    }
}

export default favoriteService;