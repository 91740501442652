import { useUI } from "@/stores/ui.js";

export function useErrorHandler(error) {
    const errorObject = error.data || error;
    const uiStore = useUI();
    if(errorObject.msg === "ERROR.CSRF_MISMATCH") {
        console.log("MISMATCH CSRF!!")
    }
    uiStore.createToast({ message: errorObject.msg || errorObject.message, type: "red", });
};
