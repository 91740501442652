<template>
    <BIcon 
        :icon="icon"
        :spin="loading"
        class="icon-btn" 
        :class="{ 
            'icon-btn--inline': props.inline,
            'icon-btn--disabled': disabled,
        }" />
</template>
<script setup>
import BIcon from "@/components/BIcon.vue";
import { computed } from "vue";

const props = defineProps({
    icon: {
        type: String,
        default: "",
    },
    loading: {
        type: Boolean,
    },
    inline: {
        type: Boolean,
    },
    disabled: {
        type: Boolean,
    }
});

const loading = computed(() => props.loading);
const disabled = computed(() => props.disabled);
const icon = computed(() => props.loading ? "loading" : props.icon);

</script>
<style scoped>
.icon-btn {
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}
.icon-btn--inline {
    margin-left: 0;
    margin-right: 0;
}
.icon-btn--disabled {
    color: #9e9e9e;
    cursor: unset;
}
.icon-btn:hover:not(.icon-btn--disabled) {
    background-color: #09648d47;
}
.icon-btn:not(.icon-btn:first-of-type) {
    margin-left: 3px;
}
</style>