import axios from "axios";
import { useCookies } from "@vueuse/integrations/useCookies";

const instance = axios.create({
  baseURL: window.location.origin + "/api",
  headers: {
    accept: "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const cookies = useCookies(["csrf"]);
    const csrfToken = cookies.get("csrf");
    if (config.headers && csrfToken) {
      config.headers["X-CSRF-TOKEN"] = csrfToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response)
);
export default instance;
