const UID_REGEX = "[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{18}";
const UID_OR_NEW_REGEX = `new|${ UID_REGEX }`;

export default [
    // AUTH
    {
      path: "/login",
      name: "login",
      component: () => import('@/views/LoginView.vue'),
    },
    {
      path: '/',
      name: 'home',
      redirect: (to )=> {
        return { path: "/hub", };
      },
    },
    // UPGRADING
    {
      path: '/upgrading',
      name: 'upgrading',
      component: () => import('@/views/UpgradeView.vue'),
    },
    // FEED
    {
      path: "/hub",
      name: "hub",
      alias: ["/feed"],
      component: () => import('@/views/HubView.vue'),
      meta: {
        setRedirect: true,
        private: true,
      }
    },
    // PROFILE
    {
      path: "/profile",
      name: "profile",
      component: () => import('@/views/ProfileView.vue'),
      meta: {
        setRedirect: true,
        private: true,
      }
    },
    {
      path: "/platform",
      name: "platform",
      component: () => import('@/views/PlatformView.vue'),
      meta: {
        setRedirect: true,
        adminOnly: true,
        private: true,
      }
    },
    // DASHBOARD
    {
      path: `/dashboard/:uid(${ UID_REGEX })`,
      component: () => import('@/views/DashboardView.vue'),
      meta: {
        setRedirect: true,
        private: true,
      },
    },
    {
      path: `/:tableName`,
      children: [
        {
          path: "",
          redirect: (to) => {
            return {
              path: `/${ to.params.tableName }/list`,
            };
          },
        },
        {
          path: `new`,
          redirect: (to) => {
            return {
              path: `/${ to.params.tableName }/form/new`
            }
          }
        },
        {
          path: `list`,
          name: `dynamic-list`,
          component: () => import("@/views/ListView.vue"),
          meta: {
            setRedirect: true,
            private: true
          }
        },
        {
          path: `kanban`,
          name: `dynamic-kanban`,
          component: () => import("@/views/KanbanView.vue"),
          meta: {
            setRedirect: true,
            private: true,
          }
        },
        {
          path: `calendar`,
          name: `dynamic-calendar`,
          component: () => import("@/views/CalendarView.vue"),
          meta: {
            setRedirect: true,
            private: true,
          }
        },
        {
          path: `form`,
          redirect: (to) => {
            return { path: `/${ to.params.tableName }/form/new`, };
          },
        },
        {
          path: `form/:uid(${ UID_OR_NEW_REGEX })`,
          name: `dynamic-form`,
          component: () => import("@/views/FormView.vue"),
          meta: {
            private: true,
          }
        }
      ],
      meta: {
        private: true,
      }
    },
  ]