<template>
  <SidebarLayout v-if="$route.meta.private">
    <RouterView />
  </SidebarLayout>
  <FullwidthLayout v-else>
    <RouterView />
  </FullwidthLayout>
  <TheToastContainer />
</template>

<script setup>
import { defineAsyncComponent } from "vue";
import { useAuth } from "@/stores/auth.js";
import { useRouter, RouterView } from "vue-router";
import TheToastContainer from "@/components/TheToastContainer.vue";

const FullwidthLayout = defineAsyncComponent(() => import('@/layouts/FullwidthLayout.vue'));
const SidebarLayout = defineAsyncComponent(() => import('@/layouts/SidebarLayout.vue'));

const router = useRouter();
const authStore = useAuth();
authStore.$subscribe((_, state) => {
  if (!state.loggedIn) {
    router.push("/login");
  }
});
</script>
