import instance from "./base.js";

const authService = {
    login(email, password) {
        return instance.post("/auth/login", { email, password, });
    },
    impersonate(uid) {
        return instance.post("/auth/impersonate", { uid, });
    },
    getProfile() {
        return instance.get("/auth/profile");
    },
    logout() {
        return instance.get("/auth/logout");
    },
    suspend(uid) {
        return instance.post("/auth/suspend", { uid, });
    }
};

export default authService;