import { useUI } from "./ui.js";
import { ref, computed, } from "vue";
import { defineStore } from 'pinia'

export const useAuth = defineStore('auth', () => {
    const loggedInRef = ref(false);
    const userRef = ref(null);
    const uiStore = useUI();
    const login = (user) => {
        userRef.value = user;
        loggedInRef.value = true;
        uiStore.setLanguage(user.language);
        uiStore.getNotifications();
        uiStore.getFavorites();
    };
    const logout = () => {
        userRef.value = null;
        loggedInRef.value = false;
    };
    const hasRole = (roleName) => {
        const roles = userRef.value?.roles;
        return roles && roles.includes(roleName);
    };
    const canImpersonate = computed(() => {
        return hasRole("impersonator") || hasRole("admin") || !!userRef?.value?.impersonator;
    });
    return { user: userRef, loggedIn: loggedInRef, canImpersonate, login, logout, hasRole, };
})