<template>
    <section class="toast-container">
        <div v-if="!dismissed && uiStore.getSetting('TENANT_TYPE', 'production') === 'subproduction'" class="tag tag--red tag-notice">
            <b>WARNING:</b> SUBPRODUCTION INSTANCE
            <BIconButton icon="close" @click="dismissed = true" inline data-cy="dismiss" />
        </div>
        <Toast v-for="(toast, toastIndex) of uiStore.toasts" :key="toastIndex" :toast="toast" />
    </section>
</template>

<script setup>
import { ref } from "vue";
import { useUI } from "@/stores/ui.js";
import Toast from "@/components/Toast.vue";
import BIconButton from "@/components/BIconButton.vue";

const uiStore = useUI();
const dismissed = ref(false);
</script>

<style scoped>
.toast-container {
    position: fixed;
    z-index: 100000;
    width: 100%;
    top: 10px;
    pointer-events: none;
    display: grid;
    justify-items: center;
    justify-content: center;
    gap: 1vh;
    overflow-y: hidden;
    max-height: calc(100% - 10px);
}
.tag-notice {
    padding: 10px;
    white-space: wrap;
    text-align: center;
    pointer-events: all;
}
</style>